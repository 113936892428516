// AthletePopup.tsx
import React, { useState } from 'react';
import BuyEquityPopup from './BuyEquityPopup';
import { Athlete } from '../Declerations';

interface AthletePopupProps {
  athlete: Athlete;
  onClose: () => void;
}

const AthletePopup: React.FC<AthletePopupProps> = ({ athlete, onClose }) => {
  const [buyPopupOpen, setBuyPopupOpen] = useState(false);
  const [thankYouPopupOpen, setThankYouPopupOpen] = useState(false);
  const [purchasedAmount, setPurchasedAmount] = useState<number | null>(null);

  const handlePurchase = (amount: number) => {
    setPurchasedAmount(amount);
    setThankYouPopupOpen(true);
  };


  return (
    <div className="popup-overlay">
      <div className="popup-container">
        <button className="popup-close" onClick={onClose}>
          ✕
        </button>
      {/* Image Row */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '1rem', // Spacing between images
          marginBottom: '0.5rem', // Add spacing below the row
        }}
      >
        <img
          src={athlete.image}
          alt={`${athlete.firstName} ${athlete.lastName}`}
          style={{
            width: '15rem',
            height: '25rem',
            objectFit: 'cover',
            borderRadius: '0.5rem',
          }}
        />
        <img
          src={athlete.image2}
          alt={`${athlete.firstName} ${athlete.lastName}`}
          style={{
            width: '15rem',
            height: '25rem',
            objectFit: 'cover',
            borderRadius: '0.5rem',
          }}
        />
        <img
          src={athlete.image3}
          alt={`${athlete.firstName} ${athlete.lastName}`}
          style={{
            width: '15rem',
            height: '25rem',
            objectFit: 'cover',
            borderRadius: '0.5rem',
          }}
        />
      </div>
        <h2 className="popup-title">
          {athlete.firstName} {athlete.lastName}
        </h2>
        <p className="popup-details">
          {athlete.country} | {athlete.sport} | Age: {athlete.age}
        </p>
        <p className="popup-description">{athlete.detailedSummary}</p>
        <div className="popup-statistics">
          {Object.entries(athlete.statistics).map(([key, value]) => (
            <p key={key}>{value}</p>
          ))}
        </div>
        <button
          className="button-primary mt-4"
          onClick={() => setBuyPopupOpen(true)}
        >
          Buy Equity
        </button>
      </div>
      {/* Buy Equity Popup */}
      {buyPopupOpen && (
        <BuyEquityPopup
          athlete={athlete}
          onClose={() => setBuyPopupOpen(false)}
          onPurchase={(amount) => {
            setBuyPopupOpen(false);
            handlePurchase(amount);
          }}
        />
      )}

      {/* Thank You Popup */}
      {thankYouPopupOpen && (
        <div className="popup-overlay">
          <div className="popup-container">
            <button className="popup-close" onClick={() => setThankYouPopupOpen(false)}>
              ✕
            </button>
            <h2 className="popup-title">Thank You!</h2>
            <p>
              Thank you for supporting {athlete.firstName} {athlete.lastName} with €{purchasedAmount}!
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default AthletePopup;
