// BuyEquityPopup.tsx
import React, { useState } from 'react';
import { Athlete } from '../Declerations';

interface BuyEquityPopupProps {
  athlete: Athlete;
  onClose: () => void;
  onPurchase: (amount: number) => void; // Add this prop for callback
}

const BuyEquityPopup: React.FC<BuyEquityPopupProps> = ({ athlete, onClose, onPurchase }) => {
  const [quantity, setQuantity] = useState<number | ''>('');
  const [totalEUR, setTotalEUR] = useState<number | ''>('');

  const handleQuantityChange = (value: string) => {
    const parsedValue = parseFloat(value);
    if (!isNaN(parsedValue)) {
      setQuantity(parsedValue);
      setTotalEUR(parsedValue * athlete.pricePerShare);
    } else {
      setQuantity(''); // Reset if invalid input
      setTotalEUR('');
    }
  };

  const handleTotalEURChange = (value: string) => {
    const parsedValue = parseFloat(value);
    if (!isNaN(parsedValue)) {
      setTotalEUR(parsedValue);
      setQuantity(parsedValue / athlete.pricePerShare);
    } else {
      setTotalEUR('');
      setQuantity('');
    }
  };

  const handleBuy = () => {
    if (quantity && totalEUR) {
      athlete.equityIssued += totalEUR;
      athlete.equityRemaining -= totalEUR;

      // Trigger the onPurchase callback
      onPurchase(totalEUR);

      // Close the popup
      onClose();
    }
  };


  return (
    <div className="popup-overlay">
      <div className="popup-container">
        <button className="popup-close" onClick={onClose}>
          ✕
        </button>
        <h2 className="popup-title">
          Buy Equity in {athlete.firstName} {athlete.lastName}
        </h2>
        <div className="popup-input-group">
          <label className="popup-label">Shares:</label>
          <input
            type="text"
            value={quantity !== '' ? quantity.toString() : ''}
            onChange={(e) => handleQuantityChange(e.target.value)}
            className="popup-input"
          />
        </div>
        <div className="popup-input-group">
          <label className="popup-label">Total EUR:</label>
          <input
            type="text"
            value={totalEUR !== '' ? totalEUR.toString() : ''}
            onChange={(e) => handleTotalEURChange(e.target.value)}
            className="popup-input"
          />
        </div>
        <button
          className="button-primary mt-4"
          onClick={handleBuy}
          disabled={!quantity || !totalEUR}
        >
          Confirm Purchase
        </button>
      </div>
    </div>
  );
};

export default BuyEquityPopup;
