import React from 'react';
//import './theme.css';

const ForInvestors: React.FC = () => {
    return (
        <div>
          For Investors
        </div>
    );
};

export default ForInvestors;
