import React, { useState } from 'react';
import { Athlete } from '../Declerations';
import AthleteCard from './AthleteCard';
import AthletePopup from './AthletePopup';
import athletesDataRaw from '../data/athletes.json';

const isAthleteArray = (data: any): data is Athlete[] => {
  return Array.isArray(data) && data.every(item => 
    typeof item.firstName === 'string' &&
    typeof item.lastName === 'string' &&
    typeof item.country === 'string' &&
    typeof item.sport === 'string' &&
    typeof item.age === 'number' &&
    typeof item.equityIssued === 'number' &&
    //typeof item.totalEquityPP === 'number' &&
    typeof item.equityRemaining === 'number' &&
    typeof item.pricePerShare === 'number' &&
    typeof item.careerSummary === 'string' &&
    typeof item.statistics === 'object' &&
    typeof item.image === 'string'
  );
};

const athletesData: Athlete[] = isAthleteArray(athletesDataRaw) ? athletesDataRaw : [];

const AthleteList: React.FC = () => {
  const [selectedAthlete, setSelectedAthlete] = useState<Athlete | null>(null);

  return (
    <div>
      <div className="p-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {athletesData.map((athlete, index) => (
          <AthleteCard
            key={index}
            athlete={athlete}
            onClick={() => setSelectedAthlete(athlete)}
          />
        ))}
        {selectedAthlete && (
          <AthletePopup
            athlete={selectedAthlete}
            onClose={() => setSelectedAthlete(null)}
          />
        )}
      </div>
    </div>

  );
};

export default AthleteList;
