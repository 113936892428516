// ForAthletes.tsx
import React from 'react';
import 'styles/theme.css'; // Ensure your CSS theme is properly loaded

const ForAthletes: React.FC = () => {
    return (
        <div className="for-athletes-page">
            {/* Top Banner Image */}
            <section
                style={{
                    backgroundImage: "url('/images/design/football_wide.png')",
                    backgroundSize: 'cover',
                    //backgroundPosition: 'center',
                    backgroundPosition: '0% 75%', // 30% from the left, 20% from the top
                    height: '50vh',
                    maxHeight: '300px',
                    width: '100%',
                    minWidth: '600px',
                }}
            >
                <div
                    style={{
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        //background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.9))',
                        background: 'linear-gradient(to top, rgba(0, 0, 0, 0.95), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.95))',
                        color: 'var(--text-color)',
                        textAlign: 'center',
                        padding: '20px',
                        
                    }}
                >
                    
                </div>
            </section>

            {/* Section 1 */}
            <section
                style={{
                    padding: '50px',
                    backgroundColor: 'var(--background-color)',
                    color: 'var(--text-color)',
                    textAlign: 'center',
                    lineHeight: '2', // Consistent line spacing
                }}
            >
                <h2 style={{ color: 'var(--text-color)', marginBottom: '20px', fontWeight: 'bold', fontSize: '2rem' }}>
                    CHANCE FOR ALL
                </h2>
                <p>
                    <strong style={{ color: 'var(--primary-color)' }}>
                        BENEFIT FOR ATHLETES
                    </strong>
                </p>
                <p style={{ maxWidth: '800px', margin: '0 auto' }}>
                    Athletes from rural areas, financially challenged backgrounds, underfunded sports, or just seeking
                    growth can create profiles showcasing their story, talent, achievements, and goals.
                </p>
            </section>

            {/* Section with Vertical Split */}
            <section
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    backgroundColor: 'var(--background-color)',
                    
                    color: 'var(--text-color)',
                    //padding: '50px',
                    gap: '20px', // Optional: Adds spacing between the image and text
                }}
            >
                {/* Left Side: Image with Gradient */}
                <div
                  style={{
                      flex: '1',
                      position: 'relative', // Allows positioning for the gradient effect
                      height: '600px',
                      //borderRadius: '10px', // Smooth corners for both the image and the gradient
                      overflow: 'hidden', // Ensures the gradient and image are clipped to the rounded edges
                  }}
              >
                  {/* Tennis image */}
                  <div
                      style={{
                          backgroundImage: "url('/images/design/tennis.png')",
                          backgroundSize: 'cover',
                          //backgroundPosition: 'center',
                          backgroundPosition: '0% 70%', // 30% from the left, 20% from the top
                          height: '100%',
                          width: '100%',
                          zIndex: 1,
                          position: 'absolute',
                      }}
                  ></div>

                  {/* Gradient Overlay */}
                  <div
                      style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          /*background: `
                            radial-gradient(circle at top left, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0) 30%),
                            radial-gradient(circle at top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0) 30%),
                            radial-gradient(circle at top right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0) 30%),
                            radial-gradient(circle at right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0) 30%),
                            radial-gradient(circle at left, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0) 30%),
                            radial-gradient(circle at bottom left, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0) 30%),
                            radial-gradient(circle at bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0) 30%),
                            radial-gradient(circle at bottom right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0) 30%)
                          `,*/
                          /*background: `
                            radial-gradient(circle, 
                                rgba(0, 0, 0, 0) 65%, 
                                rgba(0, 0, 0, 0.6) 85%, 
                                rgba(0, 0, 0, 1) 100%)
                          `,*/
                          //background: 'linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3) 200%)',
                          //backgroundBlendMode: 'overlay', // Ensures gradients blend correctly with the image
                          background: 'linear-gradient(to left, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3))',
                          zIndex: 2,
                          pointerEvents: 'none', // Ensures the gradient does not interfere with interactions
                      }}
                  ></div>
                  
              </div>

            {/* Right Side: Text */}
            <div
                style={{
                    flex: '1',
                    textAlign: 'center', // Center-align the main heading
                    padding: '50px',
                    lineHeight: '2', // Add consistent line spacing
                }}
            >
                <strong style={{ color: 'var(--primary-color)', marginBottom: '20px' }}>
                    DEVELOPMENT
                </strong>
                <p style={{ maxWidth: '800px', margin: '0 auto', textAlign: 'center' }}>
                    Investors can directly fund young athlete development, covering training, equipment, travel for competitions, events, or educational support.
                </p>
            </div>
            </section>
            {/* Section with Text on Left and Stacked Images on Right */}
            <section
                style={{
                    display: 'flex',
                    alignItems: 'flex-start', // Align items to the top
                    justifyContent: 'space-between',
                    //padding: '50px',
                    backgroundColor: 'var(--background-color)',
                    color: 'var(--text-color)',
                    //gap: '20px',
                }}
            >
                {/* Left Side: Text Content */}
                <div
                    style={{
                        width: '50%', // Dynamically takes 50% of the viewport width
                        margin: '0 auto', // Center the outer div horizontally
                        marginTop: '50px', // Add spacing at the top
                        display: 'flex',
                        flexDirection: 'column', // Ensure inner content stacks properly
                        justifyContent: 'center', // Center align content
                        alignItems: 'center', // Center items horizontally
                    }}
                >
                    <div
                        style={{
                            flex: '1',
                            textAlign: 'center', // Center-align main text
                            padding: '75px',
                            maxWidth: '800px', // Width limit for the inner text box
                            margin: '0 auto', // Center the text box within the outer div
                            lineHeight: '2', // Increased line spacing
                        }}
                    >
                        <strong style={{ color: 'var(--primary-color)' }}>
                            WHAT’S IN IT FOR INVESTORS?
                        </strong>
                        <p>
                            Sponsors and investors can discover and nurture future sports stars while becoming part of a broader
                            movement of fostering youth development through sport.
                        </p>
                        <p>
                            They can secure future endorsement opportunities or business partnerships with athletes and enjoy
                            financial returns from equity-style sponsorships.
                        </p>
                        <p style={{ marginTop: '20px' }}>
                            <strong style={{ color: 'var(--primary-color)' }}>
                                FINANCIAL RETURN (EQUITY-STYLE SPONSORSHIPS)
                            </strong>
                        </p>
                        <ul style={{ textAlign: 'left', listStyleType: 'disc', marginLeft: '50px' }}>
                            <li>Percentage of the athlete’s future earnings.</li>
                            <li>Prize money, endorsements, or contracts.</li>
                        </ul>
                        <p style={{ marginTop: '20px' }}>
                            <strong style={{ color: 'var(--primary-color)' }}>
                                EXCLUSIVE PERKS & VIP ACCESS
                            </strong>
                        </p>
                        <ul style={{ textAlign: 'left', listStyleType: 'disc', marginLeft: '50px' }}>
                            <li>Branded merchandise</li>
                            <li>Meet-and-Greet opportunities</li>
                            <li>Personalized updates</li>
                            <li>Event access</li>
                        </ul>
                        <p style={{ marginTop: '20px' }}>
                            <strong style={{ color: 'var(--primary-color)' }}>
                                WHY?
                            </strong>
                        </p>
                        <p>
                            By democratizing access to funding and international exposure, Fortius Funding would help bridge the gap for young athletes who have the talent but not the resources. 
                        </p>
                        <p>
                            This could lead to more diverse representation at higher levels of competition, ensuring that talented individuals from all backgrounds have a fair shot at success.
                        </p>
                    </div>
                </div>



                {/* Right Side: Stacked Images */}
                <div
                    style={{
                        flex: '1',
                        display: 'flex',
                        flexDirection: 'column', // Stack images vertically
                        gap: '0px', // Spacing between images
                    }}
                >
                    {/* First Image */}
                    <div
                        style={{
                            backgroundImage: "url('/images/design/triathlon.png')",
                            backgroundSize: 'cover',
                            //backgroundPosition: 'center',
                            backgroundPosition: '0% 25%', // 30% from the left, 20% from the top
                            height: '50vh', // Adjust height to fit two stacked images
                            //borderRadius: '10px', // Optional: Rounded corners
                            width: '100%', // Full width of the right column
                            borderRadius: '0', // No rounding for flush alignment
                            overflow: 'hidden',
                            position: 'relative',
                        }}
                    >
                        {/* Gradient Overlay */}
                        <div
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                background: 'linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5))',
                            }}
                        />
                    </div>

                    {/* Second Image */}
                    <div
                        style={{
                            backgroundImage: "url('/images/design/tennis2.png')",
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            //height: '45vh', // Same height as the first image
                            //borderRadius: '10px', // Optional: Rounded corners
                            height: '50vh', // Same height as the first image
                            width: '100%', // Full width of the right column
                            borderRadius: '0', // No rounding for flush alignment
                            overflow: 'hidden',
                            position: 'relative',
                        }}
                    >
                        {/* Gradient Overlay */}
                        <div
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                background: 'linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5))',
                            }}
                        />
                    </div>
                </div>
            </section>

            {/* Section with Images on the Left and Text on the Right */}
            <section
                style={{
                    display: 'flex',
                    alignItems: 'flex-start', // Align items to the top
                    justifyContent: 'space-between',
                    backgroundColor: 'var(--background-color)',
                    color: 'var(--text-color)',
                }}
            >
                {/* Left Side: Stacked Images */}
                <div
                    style={{
                        flex: '1',
                        display: 'flex',
                        flexDirection: 'column', // Stack images vertically
                        gap: '0px', // No spacing between images
                    }}
                >
                    {/* First Image */}
                    <div
                        style={{
                            backgroundImage: "url('/images/design/basketball.png')",
                            backgroundSize: 'cover',
                            //backgroundPosition: 'center',
                            backgroundPosition: '0% 15%', // 30% from the left, 20% from the top
                            height: '100vh', // Adjust height to fit two stacked images
                            width: '100%', // Full width of the left column
                            overflow: 'hidden',
                            position: 'relative',
                        }}
                    >
                        {/* Gradient Overlay */}
                        <div
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                background: 'linear-gradient(to left, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5))',
                            }}
                        />
                    </div>

                    {/* Second Image */}
                    {/*}
                    <div
                        style={{
                            backgroundImage: "url('/images/design/golf.png')",
                            backgroundSize: 'cover',
                            backgroundPosition: '0% 70%', // 30% from the left, 20% from the top
                            height: '50vh', // Same height as the first image
                            width: '100%', // Full width of the left column
                            overflow: 'hidden',
                            position: 'relative',
                        }}
                    >
                        {/* Gradient Overlay *//*}
                        <div
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                background: 'linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5))',
                            }}
                        />
                    </div>
                    {*/}
                </div>
                
                {/* Right Side: Text Content */}
                <div
                    style={{
                        width: '50%', // Dynamically takes 50% of the viewport width
                        margin: '0 auto', // Center the outer div horizontally
                        marginTop: '50px', // Add spacing at the top
                        display: 'flex',
                        flexDirection: 'column', // Ensure inner content stacks properly
                        justifyContent: 'center', // Center align content
                        alignItems: 'center', // Center items horizontally
                    }}
                >
                    <div
                        style={{
                            flex: '1',
                            textAlign: 'center', // Center-align main text
                            padding: '75px',
                            maxWidth: '800px', // Width limit for the inner text box
                            margin: '0 auto', // Center the text box within the outer div
                            lineHeight: '2', // Increased line spacing
                        }}
                    >
                        <p style={{ marginTop: '20px' }}>
                            <strong style={{ color: 'var(--primary-color)' }}>MENTORSHIP OPPORTUNITIES</strong>
                        </p>
                        <ul style={{ textAlign: 'left', listStyleType: 'disc', marginLeft: '50px' }}>
                            <li>Sponsors in fields like media, sports management, or marketing can offer professional services, creating long-term relationships with athletes.</li>
                            <li>Guidance, advice, and mentorship, helping young athletes develop skills like personal branding, networking, or media presence.</li>
                        </ul>

                        <p style={{ marginTop: '20px' }}>
                            <strong style={{ color: 'var(--primary-color)' }}>BUILDING A BRAND</strong>
                        </p>
                        <ul style={{ textAlign: 'left', listStyleType: 'disc', marginLeft: '50px' }}>
                            <li>Investing in young athletes aligns with CSR initiatives by fostering youth development, education, and equal opportunities.</li>
                            <li>Sponsors get visibility and recognition.</li>
                            <li>Naming Rights/Branding.</li>
                        </ul>

                        <p style={{ marginTop: '20px' }}>
                            <strong style={{ color: 'var(--primary-color)' }}>NETWORKING AND COMMUNITY ENGAGEMENT</strong>
                        </p>
                        <ul style={{ textAlign: 'left', listStyleType: 'disc', marginLeft: '50px' }}>
                            <li>Being part of a global community of sponsors, investors will be able to connect with like-minded individuals and companies.</li>
                            <li>Top sponsors can be featured on the platform’s leaderboard, gaining status and recognition within the SportsFunded community.</li>
                        </ul>

                        <p style={{ marginTop: '20px' }}>
                            <strong style={{ color: 'var(--primary-color)' }}>EMOTIONAL AND SOCIAL REWARDS</strong>
                        </p>
                        <ul style={{ textAlign: 'left', listStyleType: 'disc', marginLeft: '50px' }}>
                            <li>Emotional satisfaction from supporting young talent and being part of their journey to success, knowing they are making a meaningful impact.</li>
                            <li>Contribution to reducing inequalities and promoting fair opportunities in sports.</li>
                        </ul>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default ForAthletes;
