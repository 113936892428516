// Model.tsx
import React from 'react';
import 'styles/theme.css'; // Ensure your CSS theme is properly loaded

const Model: React.FC = () => {
    return (
        <div className="business-model-page">
            {/* Top Banner Image */}
            <section
                style={{
                    backgroundImage: "url('/images/design/bird_wide.png')",
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    height: '50vh',
                    maxHeight: '300px',
                    width: '100%',
                }}
            >
                <div
                    style={{
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        background: 'linear-gradient(to top, rgba(0, 0, 0, 0.95), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.95))',
                        color: 'var(--text-color)',
                        textAlign: 'center',
                        padding: '20px',
                    }}
                >
                    <h1 style={{ color: 'var(--primary-color)', fontSize: '3rem' }}>BUSINESS MODEL</h1>
                </div>
            </section>

            {/* Section 1: Platform Fees */}
            <section
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    backgroundColor: 'var(--background-color)',
                    color: 'var(--text-color)',
                    padding: '50px',
                    gap: '20px',
                }}
            >
                {/* Left Side: Image */}
                <div
                    style={{
                        flex: '1',
                        position: 'relative',
                        height: '600px',
                        overflow: 'hidden',
                    }}
                >
                    <div
                        style={{
                            backgroundImage: "url('/images/design/tennis3.png')",
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            height: '100%',
                            width: '100%',
                        }}
                    />
                </div>

                {/* Right Side: Text Content */}
                <div
                    style={{
                        flex: '1',
                        textAlign: 'center',
                        padding: '50px',
                        lineHeight: '2',
                    }}
                >
                    <strong style={{ color: 'var(--primary-color)', marginBottom: '20px' }}>
                        WHERE IS THE MONEY?
                    </strong>
                    <p>
                        <strong style={{ color: 'var(--primary-color)' }}>1. PLATFORM FEES</strong>
                    </p>
                    <p>
                        <strong>PERCENTAGE OF SPONSORSHIPS/CROWDFUNDING</strong>
                        <br />
                        SportsFunded will earn a small percentage of the total funds raised through the platform. This
                        fee applies to all sponsorship transactions, similar to models used by platforms like
                        Kickstarter or GoFundMe.
                    </p>
                    <p>
                        <strong>TRANSACTION FEES</strong>
                        <br />
                        Additional minimal processing fees for handling financial transactions on the platform, ensuring
                        a smooth flow of sponsorship funds from sponsors to athletes.
                    </p>
                </div>
            </section>

            {/* Section 2: Premium Memberships */}
            <section
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    backgroundColor: 'var(--background-color)',
                    color: 'var(--text-color)',
                    padding: '50px',
                    gap: '20px',
                }}
            >
                {/* Right Side: Image */}
                <div
                    style={{
                        flex: '1',
                        position: 'relative',
                        height: '600px',
                        overflow: 'hidden',
                    }}
                >
                    <div
                        style={{
                            backgroundImage: "url('/images/design/yellow_team.png')",
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            height: '100%',
                            width: '100%',
                        }}
                    />
                </div>

                {/* Left Side: Text Content */}
                <div
                    style={{
                        flex: '1',
                        textAlign: 'center',
                        padding: '50px',
                        lineHeight: '2',
                    }}
                >
                    <p>
                        <strong style={{ color: 'var(--primary-color)' }}>2. PREMIUM MEMBERSHIPS</strong>
                    </p>
                    <p>
                        <strong>EXCLUSIVE ACCESS FOR SPONSORS</strong>
                        <br />
                        Access to exclusive athlete profiles, detailed performance data, and direct communication tools
                        with athletes. Premium members may also receive early access to top talent.
                    </p>
                    <p>
                        <strong>TIERED SUBSCRIPTION MODELS</strong>
                        <br />
                        Different subscription levels for sponsors based on the level of involvement and perks they
                        desire. Higher tiers could offer special features like priority sponsorship placement, advanced
                        analytics, or exclusive branding opportunities.
                    </p>
                </div>
            </section>

                      {/* Section 3: Advertising and Sponsorships */}
                      <section
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    backgroundColor: 'var(--background-color)',
                    color: 'var(--text-color)',
                    padding: '50px',
                    gap: '20px',
                }}
            >
                {/* Left Side: Image */}
                <div
                    style={{
                        flex: '1',
                        position: 'relative',
                        height: '600px',
                        overflow: 'hidden',
                    }}
                >
                    <div
                        style={{
                            backgroundImage: "url('/images/design/basketball.png')",
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            height: '100%',
                            width: '100%',
                        }}
                    />
                </div>

                {/* Right Side: Text Content */}
                <div
                    style={{
                        flex: '1',
                        textAlign: 'center',
                        padding: '50px',
                        lineHeight: '2',
                    }}
                >
                    <p>
                        <strong style={{ color: 'var(--primary-color)' }}>3. ADVERTISING AND SPONSORSHIPS</strong>
                    </p>
                    <p>
                        <strong>BRANDED CONTENT AND ADVERTISING</strong>
                        <br />
                        Sports-related brands (e.g., sportswear, nutrition companies) can advertise directly on the platform. These could be featured on athlete profiles, sponsor dashboards, or general site pages, offering targeted exposure to sports fans and sponsors.
                    </p>
                    <p>
                        <strong>SPONSORED ATHLETE CAMPAIGNS</strong>
                        <br />
                        Brands or organizations could run special campaigns where they sponsor a group of athletes that fit specific criteria (e.g., a nutrition company sponsoring a group of track and field athletes). The platform could charge brands a premium fee for organizing these campaigns.
                    </p>
                    <p>
                        <strong>INSTITUTIONAL SPONSORSHIP PROGRAMS</strong>
                        <br />
                        Help sports institutions or governing bodies run large-scale sponsorship programs on the platform. Charge a management fee for organizing and handling sponsorship distribution, ensuring transparent fund usage and monitoring.
                    </p>
                </div>
            </section>

            {/* Section 4: Organizing Branded Events & Tournaments */}
            <section
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    backgroundColor: 'var(--background-color)',
                    color: 'var(--text-color)',
                    padding: '50px',
                    gap: '20px',
                }}
            >
                {/* Right Side: Image */}
                <div
                    style={{
                        flex: '1',
                        position: 'relative',
                        height: '600px',
                        overflow: 'hidden',
                    }}
                >
                    <div
                        style={{
                            backgroundImage: "url('/images/design/racket.png')",
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            height: '100%',
                            width: '100%',
                        }}
                    />
                </div>

                {/* Left Side: Text Content */}
                <div
                    style={{
                        flex: '1',
                        textAlign: 'center',
                        padding: '50px',
                        lineHeight: '2',
                    }}
                >
                    <p>
                        <strong style={{ color: 'var(--primary-color)' }}>4. ORGANIZING BRANDED EVENTS & TOURNAMENTS</strong>
                    </p>
                    <p>
                        <strong>SPORTSFUNDED-ORGANIZED COMPETITIONS</strong>
                        <br />
                        Host exclusive events, tournaments, or showcases where sponsors can see their athletes in action. These events can be monetized through entry fees, ticket sales, corporate sponsorships, or live streaming deals.
                    </p>
                    <p>
                        <strong>CORPORATE PARTNERSHIPS FOR EVENTS</strong>
                        <br />
                        Collaborate with companies to sponsor events, gaining revenue from sponsorship deals, while offering companies branding opportunities during the events.
                    </p>
                </div>
            </section>

            {/* Section 5: Athlete Success Sharing */}
            <section
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    backgroundColor: 'var(--background-color)',
                    color: 'var(--text-color)',
                    padding: '50px',
                    gap: '20px',
                }}
            >
                {/* Left Side: Image */}
                <div
                    style={{
                        flex: '1',
                        position: 'relative',
                        height: '600px',
                        overflow: 'hidden',
                    }}
                >
                    <div
                        style={{
                            backgroundImage: "url('/images/design/couch.png')",
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            height: '100%',
                            width: '100%',
                        }}
                    />
                </div>

                {/* Right Side: Text Content */}
                <div
                    style={{
                        flex: '1',
                        textAlign: 'center',
                        padding: '50px',
                        lineHeight: '2',
                    }}
                >
                    <p>
                        <strong style={{ color: 'var(--primary-color)' }}>5. ATHLETE SUCCESS SHARING (EQUITY-STYLE SPONSORSHIPS)</strong>
                    </p>
                    <p>
                        <strong>FUTURE EARNINGS SHARE</strong>
                        <br />
                        In select sponsorship agreements, sponsors could take a small percentage of the athlete’s future earnings (e.g., from endorsements, prize money, or contracts). Fortius Funding could take a percentage of these earnings as a facilitation fee, providing long-term revenue.
                    </p>
                    <p>
                        <strong>INCENTIVIZED SPONSOR-ATHLETE CONTRACTS</strong>
                        <br />
                        The platform can charge a service fee for facilitating and managing equity-style contracts, ensuring clear agreements between athletes and sponsors.
                    </p>
                </div>
            </section>
          </div>
    );
};

export default Model;
