import React from 'react';
import AthleteList from './Athletes/AthleteList';

const Home: React.FC = () => {
  const scrollToAthletes = () => {
    const section = document.getElementById('athletes');
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div>
      <section
          style={{
              backgroundImage: "url('/images/design/yellow_team.png')",
              backgroundSize: 'cover',
              backgroundPosition: '0% 45%', // 30% from the left, 20% from the top
              height: '50vh',
              maxHeight: '300px',
              width: '100%',
          }}
      >
          <div
              style={{
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  //background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.9))',
                  background: 'linear-gradient(to top, rgba(0, 0, 0, 0.95), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.95))',
                  color: 'var(--text-color)',
                  textAlign: 'center',
                  padding: '20px',
              }}
          >
          </div>
      </section>
      <section id="home" className="home-section">

        <div className="content-container text-center">
          <h1 className="heading">Fortius Funding
            <p className="description" style={{ fontWeight: 'normal', fontStyle: 'italic', fontSize: '1rem', color: 'var(--accent-color)' }}>
                Citius, Altius, Fortius
            </p>
          </h1>

          <p className="description">
            Fortius Funding is a digital platform where individuals, companies, and organizations can sponsor young athletes from various sports, helping them reach their full potential by providing financial support, mentorship, and resources.
          </p>
          <p className="description">
            The platform connects athletes with potential investors or sponsors who want to be part of their journey from the early stages of their career.
          </p>
          <p className="description">
            Our platform provides a unique opportunity to be part of their stories. As they reach new heights in their careers, you share in their achievements and successes. Together, we can build a brighter future for sports and create lasting connections between fans and athletes.
          </p>

          <button onClick={scrollToAthletes} className="explore-button">Get Started</button>

          {/* Athletes Section */}
          <section id="athletes" className="athletes-section">
            <div className="content-container">
              <h2 className="section-heading">Athletes</h2>
              <AthleteList />
            </div>
          </section>
        </div>
      </section>
    </div>

  );
};

export default Home;
