// Market.tsx
import React from 'react';
import 'styles/theme.css'; // Ensure your CSS theme is properly loaded

const Market: React.FC = () => {
    return (
        <div className="model-page">
            {/* Top Banner Image */}
            <section
              style={{
                  backgroundImage: "url('/images/design/sidelines_wide.png')",
                  backgroundSize: 'cover',
                  backgroundColor: 'black',
                  backgroundPosition: 'top',
                  //backgroundPosition: '0% 0%', // 30% from the left, 20% from the top
                  height: '50vh',
                  maxHeight: '300px',
                  width: '100%',
              }}
          >
              <div
                  style={{
                      height: '100%',
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      background: 'linear-gradient(to top, rgba(0, 0, 0, 0.95), rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.95))',
                      //background: 'linear-gradient(to top, rgba(0, 0, 0, 0.95), rgba(0, 0, 0, 0) 25%) to bottom',
                      //background: 'linear-gradient(to top, rgba(0, 0, 0, 0.95), rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.95))',
                      //color: 'var(--text-color)',
                      textAlign: 'center',
                  }}
              >
                  
              </div>
            </section>
            {/*<h1 style={{ color: 'var(--primary-color)', fontSize: '2rem', fontWeight: 'bold' }}>MARKET LANDSCAPE</h1>*/}
                        {/* Section 1 */}
                        <section
                style={{
                    padding: '50px',
                    backgroundColor: 'var(--background-color)',
                    color: 'var(--text-color)',
                    textAlign: 'center',
                    lineHeight: '2', // Consistent line spacing
                }}
            >
                <h2 style={{ color: 'var(--text-color)', marginBottom: '20px', fontWeight: 'bold', fontSize: '2rem' }}>
                    MARKET LANDSCAPE
                </h2>
            </section>
            {/* Section 1: Image on Left, Text on Right */}
            <section
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    backgroundColor: 'var(--background-color)',
                    color: 'var(--text-color)',
                    //padding: '50px',
                    //marginBottom: '50px',
                }}
            >
                {/* Left Side: Image */}
                <div
                    style={{
                        flex: '1',
                        backgroundImage: "url('/images/design/racket.png')",
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        height: '600px',
                        borderRadius: '10px',
                    }}
                ></div>

                {/* Right Side: Text */}
                <div
                    style={{
                        flex: '1',
                        paddingLeft: '50px',
                        lineHeight: '2',
                        textAlign: 'center',
                    }}
                >
                    <h2 style={{ color: 'var(--primary-color)', /*marginBottom: '5px',*/ fontSize: '1.5rem' }}>
                        SIZE & GROWTH
                    </h2>
                    <p>
                        Global sports sponsorship market was valued at over <strong style={{ color: 'var(--primary-color)' }}>€89 billion</strong> in 2023, projected to reach <strong style={{ color: 'var(--primary-color)' }}>€175 billion</strong> until 2030.
                    </p>
                    <p>
                        Global crowdfunding market size is valued at <strong style={{ color: 'var(--primary-color)' }}>€1.3 billion</strong> in 2023, estimated to exceed <strong style={{ color: 'var(--primary-color)' }}>€4.1 billion</strong> by early 2030s.
                    </p>
                    <h2 style={{ color: 'var(--primary-color)', marginTop: '30px', marginBottom: '5px', fontSize: '1.5rem' }}>
                        RISE OF ATHLETE-DRIVEN CONTENT AND PERSONAL BRANDING
                    </h2>
                    <p>
                        With the growth of social media, athletes are increasingly able to build personal brands and engage directly with fans, leading sponsors to look for individual sponsorship opportunities rather than traditional team-based sponsorships.
                    </p>
                    <h2 style={{ color: 'var(--primary-color)', marginTop: '30px', marginBottom: '5px', fontSize: '1.5rem' }}>
                        TREND
                    </h2>
                    <p>
                        Growing interest in individual-focused crowdfunding and direct sponsorship models, as seen with platforms supporting individual creators - <strong>OnlyFans, Kick, Patreon, GoFundMe, Kickstarter</strong>.
                    </p>
                </div>
            </section>

            {/* Section 2: Text on Left, Image on Right */}
            <section
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    backgroundColor: 'var(--secondary-color)',
                    color: 'var(--text-color)',
                    padding: '50px',
                }}
            >
                {/* Left Side: Text */}
                <div
                    style={{
                        flex: '1',
                        paddingRight: '50px',
                        lineHeight: '2',
                        textAlign: 'left',
                    }}
                >
                    <h2 style={{ color: 'var(--primary-color)', marginBottom: '20px', fontSize: '2rem' }}>
                        MARKET SEGMENTS FOR ATHLETES
                    </h2>
                    <p>
                        <strong>Young & Emerging Athletes:</strong> Aspiring talents from rural areas, small countries, or economically disadvantaged backgrounds in underfunded sports.
                    </p>
                    <p>
                        <strong>Olympic & International Aspirants:</strong> Athletes aiming for international competitions, requiring funding for travel, equipment, or coaching.
                    </p>
                </div>

                {/* Right Side: Image */}
                <div
                    style={{
                        flex: '1',
                        backgroundImage: "url('/images/design/training.png')",
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        height: '400px',
                        borderRadius: '10px',
                    }}
                ></div>
            </section>

            {/* Section 3: Images on Left, Text on Right */}
            <section
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    backgroundColor: 'var(--background-color)',
                    color: 'var(--text-color)',
                    padding: '50px',
                }}
            >
                {/* Left Side: Stacked Images */}
                <div
                    style={{
                        flex: '1',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                    }}
                >
                    {/* Golf Image */}
                    <div
                        style={{
                            backgroundImage: "url('/images/design/golf2.png')",
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            height: '200px',
                            borderRadius: '10px',
                        }}
                    ></div>
                    {/* Couch Image */}
                    <div
                        style={{
                            backgroundImage: "url('/images/design/couch.png')",
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            height: '200px',
                            borderRadius: '10px',
                        }}
                    ></div>
                </div>

                {/* Right Side: Text */}
                <div
                    style={{
                        flex: '1',
                        paddingLeft: '50px',
                        lineHeight: '2',
                        textAlign: 'left',
                    }}
                >
                    <h2 style={{ color: 'var(--primary-color)', marginBottom: '20px', fontSize: '2rem' }}>
                        MARKET SEGMENTS FOR SPONSORS & INVESTORS
                    </h2>
                    <p>
                        <strong>Small Investors:</strong> Similar to crowdfunding backers, interested in equity-style sponsorships, with potential ROI if an athlete turns professional.
                    </p>
                    <p>
                        <strong>Sports Federations, Clubs & Associations:</strong> Use SportsFunded to match athletes with sponsors.
                    </p>
                    <p>
                        <strong>Sports-Focused Brands:</strong> Associate with up-and-coming athletes for promotion.
                    </p>
                    <p>
                        <strong>Colleges & Universities:</strong> Nurture student-athletes or recruit future stars.
                    </p>
                    <p>
                        <strong>Sports Enthusiasts:</strong> Passionate individuals looking to personally contribute to athlete success.
                    </p>
                    <p>
                        <strong>CSR Departments:</strong> Enhance company CSR profiles through sponsorship.
                    </p>
                </div>
            </section>

            {/* Final Section: Full-Width */}
            <section
                style={{
                    padding: '50px',
                    backgroundColor: 'var(--secondary-color)',
                    color: 'var(--text-color)',
                    textAlign: 'center',
                }}
            >
                <h2 style={{ color: 'var(--primary-color)', fontSize: '2rem', fontWeight: 'bold', marginBottom: '20px' }}>
                    MARKET OPPORTUNITY: WHY NOW?
                </h2>
                <p>
                    <strong>Digital Transformation:</strong> Shift to digital platforms and remote interactions creates opportunities for connecting athletes and sponsors.
                </p>
                <p>
                    <strong>Upturn of Purpose-Driven Sponsorship:</strong> Fortius Funding aligns with this growing trend.
                </p>
                <p>
                    <strong>Scalability:</strong> Expandable across different sports, regions, and age groups, allowing for global growth into untapped markets.
                </p>
            </section>
        </div>
    );
};

export default Market;
