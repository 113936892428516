import React from 'react';
import Home from '../src/components/Home';
//import AthleteList from '../src/components/Athletes/AthleteList';
import './styles/theme.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import ForAthletes from './components/info/ForAthletes';
import ForInvestors from './components/info/ForInvestors';
import Goal from './components/info/Goal';
import Market from './components/info/Market';
import Model from './components/info/Model';


const App: React.FC = () => {
  return (
      <Router>
          <Navbar />
          <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/for-athletes" element={<ForAthletes />} />
              <Route path="/for-investors" element={<ForInvestors />} />
              <Route path="/goal" element={<Goal />} />
              <Route path="/market" element={<Market />} />
              <Route path="/model" element={<Model />} />
          </Routes>
      </Router>
  );
};

export default App;
