import React from 'react';
//import '.../;

const Goal: React.FC = () => {
    return (
        <div>
          Goal
        </div>
    );
};

export default Goal;
