// AthleteCard.tsx
import React from 'react';
import { Athlete } from '../Declerations';

interface AthleteCardProps {
  athlete: Athlete;
  onClick: () => void;
}

const AthleteCard: React.FC<AthleteCardProps> = ({ athlete, onClick }) => {
  const progress = ((athlete.equityIssued - athlete.equityRemaining) / athlete.equityIssued) * 100;

  return (
    <div className="card-container" onClick={onClick}>
      <img
        src={athlete.image}
        alt={`${athlete.firstName} ${athlete.lastName}`}
        className="card-image"
      />
      <h2 className="card-title">
        {athlete.firstName} {athlete.lastName}
      </h2>
      <p className="card-details">{athlete.country} | {athlete.sport} | Age: {athlete.age}</p>
      <p className="card-summary">{athlete.careerSummary}</p>
      <div className="card-statistics">
        {Object.entries(athlete.statistics).map(([key, value]) => (
          <p key={key}>{value}</p>
        ))}
      </div>
      <div className="card-equity-info">
        <p>Price per Share: €{athlete.pricePerShare}</p>
        <p>Equity Issued: €{athlete.equityIssued} ({athlete.totalEquityPP}%)</p>
        <p>Equity Remaining: €{athlete.equityRemaining}</p>
        <div className="progress-bar-background">
          <div
            className="progress-bar"
            style={{ width: `${progress}%` }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default AthleteCard;
