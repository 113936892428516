import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/theme.css';

const Navbar: React.FC = () => {
    // Access CSS variables dynamically
    const rootStyles = getComputedStyle(document.documentElement);
    const styles = {
        backgroundColor: rootStyles.getPropertyValue('--background-color').trim(),
        textColor: rootStyles.getPropertyValue('--text-color').trim(),
        hoverColor: rootStyles.getPropertyValue('--hover-color').trim(),
        hoverTextColor: rootStyles.getPropertyValue('--secondary-color').trim(),
    };

    return (
        <nav
            style={{
                padding: '10px',
                backgroundColor: styles.backgroundColor,
                color: styles.textColor,
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            <ul
                style={{
                    display: 'flex',
                    listStyle: 'none',
                    padding: 0,
                    margin: 0,
                }}
            >
                {[
                    { path: "/", label: "Home" },
                    { path: "/for-athletes", label: "Info" },
                    //{ path: "/for-investors", label: "For Investors" },
                    //{ path: "/goal", label: "Goal" },
                    { path: "/market", label: "Market" },
                    { path: "/model", label: "Model" },
                ].map((item) => (
                    <li key={item.path} style={{ margin: '0 15px' }}>
                        <Link
                            to={item.path}
                            style={{
                                color: styles.textColor,
                                textDecoration: 'none',
                                padding: '10px 15px',
                                borderRadius: '8px',
                                backgroundColor: styles.backgroundColor,
                                transition: 'background-color 0.3s ease, color 0.3s ease',
                            }}
                            onMouseEnter={(e) => {
                                e.currentTarget.style.backgroundColor = styles.hoverColor;
                                e.currentTarget.style.color = styles.hoverTextColor;
                            }}
                            onMouseLeave={(e) => {
                                e.currentTarget.style.backgroundColor = styles.backgroundColor;
                                e.currentTarget.style.color = styles.textColor;
                            }}
                        >
                            {item.label}
                        </Link>
                    </li>
                ))}
            </ul>
        </nav>
    );
};

export default Navbar;
